<template>
  <div class="Userhl_gfc Userhl_rightbox">
    <el-tabs v-model="activeNamesmall" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in activeNamesmallall"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <ul class="Userhl_msgznmsgul" style="padding-top: 1rem">
          <li
            class="Userhl_msgznmsgulli"
            v-for="(item, index) in gzall"
            :key="index"
          >
            <div class="Userhl_msgznmsgulliimg" @click="userlink(item.userid)">
              <img :src="item.userpic" alt="" />
            </div>
            <p>{{ item.username }}</p>
            <p>作品 30 | 粉丝 30</p>
            <p>{{ item.saytext }}</p>
            <el-button
              v-show="item.gz == 1 ? true : false"
              icon="el-icon-circle-plus-outline"
              plain
              @click="addremovegz(item.userid, index, 1)"
              >加关注</el-button
            >
            <el-button
              v-show="item.gz == 0 ? true : false"
              @click="addremovegz(item.userid, index, 2)"
              icon="el-icon-user"
              type="success"
              plain
              >已关注</el-button
            >
          </li>
          <li class="lizerogz" v-if="lizero1"></li>
          <li class="lizerogz" v-if="lizero2"></li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
        <el-pagination
      :pager-count="5"
          @current-change="currentchange_gz"
          :page-size="limit"
          background
          layout="prev, pager, next"
          :total="total_gz"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-button class="linkbtn" plain @click="linkxm('/Rencai')">查看更多设计师</el-button>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      lizero: false,
      activeNamesmall: "first",
      userinfo: {},
      activeNamesmallall: [
        {
          name: "first",
          label: "我的关注",
        },
        {
          name: "two",
          label: "关注我的",
        },
      ],
      gzall: [],
      limit: 10,
      total_gz: 0,
      f_type: 1,
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      lizero1: false,
      lizero2: false,
    };
  },
  methods: {
    linkxm(link) {
      this.$router.push({ path: link });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    currentchange_gz(index) {
      this.gzalllist(index);
    },
    gzalllist(page) {
      var that = this;
      this.lizero = true;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getFollowFans",
        method: "post",
        data: {
          userid: userinfo.userid,
          f_type: that.f_type,
          page,
          limit: that.limit,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          if (res.data.result.count == 0) {
            that.lizero = true;
          } else {
            that.lizero = false;
          }
          that
            .$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/getFollowFans",
              method: "post",
              data: {
                userid: userinfo.userid,
                f_type: "1", //我关注的人
                page: 1,
                limit: 10000,
              },
            })
            .then((re) => {
              if (re.data.code == 1) {
                for (var s = 0; s < res.data.result.list.length; s++) {
                  for (var i = 0; i < re.data.result.list.length; i++) {
                    if (
                      re.data.result.list[i].userid ==
                      res.data.result.list[s].userid
                    ) {
                      res.data.result.list[s].gz = 0;
                      break;
                    } else {
                      res.data.result.list[s].gz = 1;
                    }
                  }
                }
                that.gzall = res.data.result.list;
              }
            });
          var countzero = res.data.result.list.length % 4;
          console.log(countzero);
          if (countzero == 2) {
            that.lizero2 = true;
            that.lizero1 = true;
          } else if (countzero == 3) {
            that.lizero1 = true;
          }
        }
      });
    },
    addremovegz(id, indexid, type) {
      if (type == 1) {
        //关注
        type = "getFollow";
        console.log("加关注");
      } else {
        type = "getCancelFollow";
        console.log("取关");
      }
      console.log(id);
      var types = type;
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/" + type + "",
        method: "post",
        data: {
          member_id: id,
          userid: userinfo.userid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          if (that.gzall[indexid].gz == 0) {
            that.gzall[indexid].gz = 1;
            that.$message("取消关注成功");
          } else {
            that.gzall[indexid].gz = 0;
            that.$message({
              message: "添加关注成功",
              type: "success",
            });
          }
          this.$forceUpdate();
        }
      });
    },
    userlink(id) {
      this.$router.push({ path: "/Rencai_c", query: { data: id } });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.gzalllist(1);
  },
  watch: {
    activeNamesmall() {
      if (this.activeNamesmall == "first") {
        this.f_type = 1;
      } else {
        this.f_type = 2;
      }
      this.gzall = [];
      this.gzalllist(1);
    },
  },
};
</script>
